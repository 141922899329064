<template>
    <div class="ProgrammeCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form @submit.native.prevent :inline="true" v-model="form" size="small" label-width="80px">
            <el-card shadow="never" style="margin-top: 8px; padding-top: 16px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组">
                            <el-select
                                v-model="form.deptGroupCode"
                                filterable
                                @change="tableData = []"
                                :loading="loadingDeptGroupFlag"
                            >
                                <el-option value="" label="请选择" v-if="meta.groups && meta.groups.length > 1" />
                                <el-option
                                    v-for="group in meta.groups"
                                    :value="group.code"
                                    :key="group.code"
                                    :label="group.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input placeholder="名称/条码" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="form.creator" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-date-picker
                                v-model="form.createDate"
                                value-format="yyyy-MM-dd"
                                readonly
                                style="width: 200px"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button
                    type="primary"
                    @click="queryAndAdd"
                    size="small"
                    :disabled="!form.deptGroupCode || !form.search"
                    >查询并添加
                </el-button>
                <el-button type="primary" @click="handleSelectGoods" size="small" :disabled="!form.deptGroupCode">
                    添加在库商品
                </el-button>
                <el-button type="primary" @click="handleAddGoods" size="small" :disabled="!form.deptGroupCode"
                    >添加非在库商品
                </el-button>
                <el-button type="primary" @click="handleSave" size="small" :disabled="tableData.length <= 0"
                    >保存
                </el-button>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="tableData"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="500"
                >
                    <el-table-column label="序号" width="60" type="index" fixed="left" />
                    <el-table-column label="计划名称" width="180">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.nameList" size="mini" />
                        </template>
                    </el-table-column>
                    <el-table-column label="商品名称" width="180">
                        <template slot-scope="scope">
                            <template v-if="scope.row.typeList == 1">
                                <el-input v-model="scope.row.name" readonly size="mini" />
                            </template>
                            <template v-else>
                                <el-input v-model="scope.row.name" size="mini" />
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column label="配送类型" width="180">
                        <template slot-scope="scope">
                            <el-select placeholder="请选择" v-model="scope.row.deliveryType" size="mini">
                                <el-option label="自取" :value="1" />
                                <el-option label="配送" :value="2" />
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column prop="typeListName" label="在库商品" width="180" />
                    <el-table-column label="开始日期" width="180">
                        <template slot-scope="scope">
                            <el-date-picker
                                v-model="scope.row.startTimeList"
                                style="width: 150px"
                                value-format="yyyy-MM-dd"
                                :picker-options="expireTimeOption"
                                size="mini"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column label="结束日期" width="180">
                        <template slot-scope="scope">
                            <el-date-picker
                                v-model="scope.row.endTimeList"
                                style="width: 150px"
                                value-format="yyyy-MM-dd"
                                :picker-options="expireTimeOption"
                                size="mini"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="deleteRow(scope.$index, tableData, scope.row)"
                                >删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
        <pick-up-self-sku ref="PickupSelfSku" @ok="handleSelectGoodsOk" />
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import Util from 'js/Util';
import PickUpSelfSku from 'components/PickUpSelfSku';

export default {
    name: 'ProgrammeCreate',
    components: { PickUpSelfSku },
    data() {
        return {
            expireTimeOption: {
                disabledDate(date) {
                    return date.getTime() < Date.now() - 8.64e7;
                },
            },
            form: {
                deptGroupCode: '',
                search: '',
                createDate: new Date(),
                creator: this.$store.state.session.name,
            },
            tableData: [],
            loadingDeptGroupFlag: true,
            url: {
                batchSave: '/merchandising/save',
                querySku: '/goods/sku/page',
                queryGroups: '/system/deptGroup/staffManagedAllDeptGroups',
            },
            loading: false,
            meta: {
                groups: [],
            },
            queryData: {
                search: '',
                type: 0,
            },
        };
    },
    mounted() {
        this.inits();
    },
    filters: {
        type(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type == '0') {
                return '标品';
            }
            if (type == '1') {
                return '非标品';
            }
        },
        status(status) {
            if (typeof status == 'undefined') {
                return '';
            }
            if (status == '0') {
                return '已下架';
            }
            if (status == '1') {
                return '已上架';
            }
            if (status == '2') {
                return '已禁售';
            }
        },
    },
    methods: {
        inits() {
            UrlUtils.DeptGroup(this, (rst) => {
                this.meta.groups = rst;
                this.loadingDeptGroupFlag = false;
            });
        },
        handleSave() {
            const _this = this;
            let unValid = false;
            this.tableData.forEach((value) => {
                if (typeof value.name == 'undefined' || value.name == '') {
                    unValid = true;
                } else if (typeof value.endTimeList == 'undefined' || value.endTimeList == '') {
                    unValid = true;
                } else if (typeof value.startTimeList == 'undefined' || value.startTimeList == '') {
                    unValid = true;
                } else if (typeof value.nameList == 'undefined' || value.nameList == '') {
                    unValid = true;
                } else {
                    value.goodsNameList = value.name;
                }
            });
            if (!this.form.deptGroupCode) {
                this.$message.error('请选择机构组');
                return;
            } else if (this.tableData.length == 0) {
                this.$message.error('请添加至少一种商品计划');
                return;
            } else if (unValid) {
                this.$message.error('请填写所有的时间和名称');
                return;
            } else if (this.tableData.length >= 1) {
                for (let i = 0; i < this.tableData.length; i++) {
                    const startTime = new Date(this.tableData[i].startTimeList);
                    const endTime = new Date(this.tableData[i].endTimeList);
                    if (startTime > endTime) {
                        _this.$message.error('第' + (i + 1) + '行活动的开始时间不能迟于结束时间');
                        return;
                    }
                }
            }
            const dealEmpty = (dealData = ' ') => {
                return dealData;
            };
            const _params = {
                skuCodeList: _this.tableData.map((d) => d.code || ' '),
                nameList: _this.tableData.map((d) => d.nameList || ' '),
                goodsNameList: _this.tableData.map((d) => d.name || ' '),
                deliveryTypeList: _this.tableData.map((d) => d.deliveryType || ' '),
                typeList: _this.tableData.map((d) => dealEmpty(d.typeList)),
                startTimeList: _this.tableData.map((d) => d.startTimeList || ''),
                endTimeList: _this.tableData.map((d) => d.endTimeList || ''),
            };
            Object.assign(_params, _this.form);
            delete _params['search'];
            delete _params['createDate'];
            delete _params['page'];
            delete _params['limit'];
            delete _params['creator'];
            UrlUtils.PostRemote(this, this.url.batchSave, _params);
        },
        queryAndAdd() {
            const _this = this;
            if (!_this.form.search.trim()) {
                _this.$message.error('请输入查询条件');
                return;
            }
            this.queryData.search = this.form.search;
            const _params = { params: _this.queryData };
            Util.queryTable(_this, _this.url.querySku, _params, (data) => {
                if (data.status == 200) {
                    _this.handleSelectGoodsOk(data.data);
                }
            });
        },

        handleSelectGoods() {
            const _row = {
                type: 0,
            };
            this.$refs.PickupSelfSku.show(_row, this.url.querySku);
        },
        handleAddGoods() {
            this.tableData.push({
                typeListName: '不在库',
                typeList: 2,
                startTimeList: this.formatDate(new Date()),
                endTimeList: this.formatDate(new Date()),
                deliveryType: 1,
            });
        },
        handleSelectGoodsOk(data) {
            const _this = this;
            let _a = _this.tableData;
            data.forEach((v) => {
                const temP = {};
                temP.name = v.name;
                temP.code = v.code;
                temP.typeListName = '在库';
                temP.typeList = 1;
                temP.deliveryType = 1;
                temP.startTimeList = this.formatDate(new Date());
                temP.endTimeList = this.formatDate(new Date());
                _a.push(temP);
            });
            _a = _this.duplicate(_a, 'code');
            _this.tableData = _a;
        },
        deleteRow(index, rows, row) {
            rows.splice(index, 1);
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
